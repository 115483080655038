export default {
  text: '#81725f',
  text2: '#a89f8f',
  title: '#a89f8f',
  primary: '#a89f8f',
  secondary: '#eeeae2',
  tertiary: '#e6d6bc',
  background: '#ffffff',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#eeeae2',
  backgroundTertiary: '#ffffff',
  light: '#FFF',
  dark: '#555555'
}
