export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logoLocationContainer': {
      height: 'auto',
      padding: '0rem',
      margin: ['0rem auto 0rem 0rem', '', '', '0rem'],
      position: 'static'
    },
    '.logo': {
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['100px', '100px', '100px', '115px'],
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['50px', '60px', '60px', '60px'],
        filter: 'unset',
        padding: '0rem'
      }
    },
    '.socialIconsContainerDesktop': {
      display: 'flex',
      order: 4,
      margin: ' 0rem',
      '.socialLink': {
        svg: {
          height: '20px',
          width: '20px',
          path: {
            fill: 'dark'
          }
        }
      }
    },
    '.container': {
      padding: ['0.5rem 2rem', '', '', '0.5rem 1rem', '0.5rem 2rem'],
      // padding: ['1rem 1rem 2rem', '1rem 2rem 2rem', '5rem 2rem 2rem', '5rem 2rem 2rem 135px'],
      backgroundColor: 'white',
      top: '0rem'
    },
    '.containerScrolled': {
      '.logoLocationContainer': {
        height: 'auto',
        padding: '0rem',
        margin: ['0rem auto 0rem 0rem', '', '', '0rem'],
        position: 'static'
      },
      padding: '0.5rem 1rem',
      backgroundColor: 'background',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'primary',
      '.phoneContainer': {
        display: 'none'
      }
    },
    '.hamburger': {
      order: 5,
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: 'primary',
      backgroundColor: 'rgba(255,255,255, 0.6)',
      display: ['', '', '', 'none'],
      mx: '0rem',
      '> div': {
        backgroundColor: 'primary'
      }
    },
    '.smallNavMenu': {
      order: 3,
      mr: 'auto',
      alignItems: 'center',
      '.navItem': {
        borderColor: 'dark',
        padding: '0rem 0.25rem',
        mr: '0rem',
        a: {
          fontSize: ['0.75rem', '0.75rem', '0.75rem', '0.75rem', '0.8rem', '0.9rem'],
          ':hover': {
            color: 'black'
          }
        },
        color: 'text',
        ':hover': {
          color: 'black'
        }
      },
      '> div :nth-of-type(1)': {
        border: 'none'
      },
      '.order-online': {
        backgroundColor: 'transparent',
        padding: '0.75rem 1.5rem',
        border: 'solid 1px',
        borderColor: 'dark',
        ml: '2rem',
        ':hover': {
          color: 'primary',
          a: { color: 'primary' },
          // backgroundColor: 'primary',
          borderColor: 'primary'
        }
      },
      '.reservations': {
        backgroundColor: 'primary',
        padding: '0.75rem 1.5rem',
        color: 'white',
        border: 'solid 1px',
        borderColor: 'primary',
        ':hover': {
          color: 'primary',
          a: { color: 'primary' },
          backgroundColor: 'transparent',
          borderColor: 'primary'
        }
      }
    },

    // '.phoneContainer': {
    //   display: ['none', '', 'flex'],
    //   position: 'absolute',
    //   top: '0rem',
    //   margin: '0rem',
    //   left: '0rem',
    //   border: 'none',
    //   width: '100%',
    //   backgroundColor: 'primary',
    //   justifyContent: 'flex-end',
    //   padding: '0.75rem',
    //   a: {
    //     '::before': {
    //       content: "'Let’s talk about your next private event'",
    //       marginRight: '0.5rem',
    //       fontSize: '0.9rem'
    //     },
    //     textAlign: 'center',
    //     color: 'light',
    //     fontSize: '0.9rem',
    //     svg: {
    //       display: 'none'
    //     },
    //     ':hover': {
    //       opacity: '0.7',
    //       color: 'light',
    //       fontSize: '0.9rem'
    //     }
    //   }
    // },
    '.hamburgerOpen': {
      borderColor: 'white',
      '> div': {
        backgroundColor: 'light'
      }
    },
    '.navMenuLogo': {
      display: 'none'
    },
    '.navMenu': {
      transform: 'unset',
      top: '0rem',
      transition: 'all ease-in-out 0.5s',
      height: '100vh',
      left: '-200vw'
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      textAlign: ['', '', '', 'right'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      '.navItem': {
        a: {
          color: 'light',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          fontFamily: 'heading'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white',
        fontFamily: 'heading'
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      }
    },
    '.navBlock': {
      right: 'unset',
      top: '200vh'
    }
  },

  footer: {
    padding: '2rem',
    pb: ['15vh', '15vh', '15vh', '15vh'],
    backgroundColor: '#fffbf6',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '.multiButtonContainer': {
      display: 'none'
    },
    '.socialIconsContainer': {
      display: 'none'
    },
    '.image': {
      display: 'none'
    },

    '.contactDetails-container': {
      '::before': {
        content: "'Nômade WESTPORT'",
        letterSpacing: '2px',
        marginBottom: '3rem'
      },
      svg: {
        display: 'none'
      },
      '.text': {
        m: '0rem',
        fontSize: '13px',
        color: 'text2',
        letterSpacing: '1px'
      },
      '.phone': {
        marginTop: '1.5rem',
        textDecoration: 'underline',
        color: 'tertiary'
      }
    },
    '.copyright': {
      display: 'none'
    },
    '.gonationLogo': {
      mt: '1rem',
      filter: 'brightness(0.7)',
      span: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
      }
    }
  },

  ctaWidget: {
    '#call-cta': {
      display: ['', '', 'none']
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['1.5rem', '1.75rem', '2rem', '2.25rem', '2.5rem'],
    fontWeight: 'light',
    letterSpacing: '4px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    fontFamily: 'heading',
    fontWeight: '300',
    color: 'title',
    textTransform: 'uppercase'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.15rem', '1.25rem', '1.5rem', '1.75rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'primary',
    fontFamily: 'subheading',
    opacity: '0.8',
    fontWeight: '200',
    textTransform: 'uppercase'
  },
  text: {
    lineHeight: '1.75',
    fontSize: ['1rem', '', '1.1rem'],
    padding: '0.25rem 0rem',
    opacity: '0.6',
    p: {
      color: 'inherit',
      lineHeight: '2',
      textAlign: 'inherit',
      letterSpacing: '1.8px',
      fontSize: ['0.7rem', '', '0.8rem']
    }
  },

  sideBySide1: {
    height: ['', '', '85vh', '85vh'],
    padding: ['1rem', '', '3rem'],
    backgroundColor: 'background',
    margin: '0rem',
    '.lazyload-wrapper': {
      minHeight: '50vh',
      justifyContent: 'flex-start',
      width: ['', '', '60%', '60%']
    },
    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      width: ['', '', '40%', '40%']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.videoContainer': {
      height: ['75vh', '', '', '100vh'],
      marginTop: '4rem'
    },

    width: '100%',
    justifyContent: 'center',
    padding: ['0rem 0.5rem 2rem', '', '0rem 2rem 2rem'],
    '.title': {
      variant: 'customVariants.title',
      color: 'white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },
  homepageShout: {
    transform: ['', '', '', 'translate(-50%, 0)'],
    position: ['', '', '', 'relative'],
    top: '0rem',
    zIndex: ['', '', '', '500'],
    // width: ['', '', '', '85%'],
    left: ['', '', '', '50%'],
    display: 'flex',
    flexDirection: 'row',
    maxWidth: ['', '', '', '900px'],
    borderRadius: '10px',
    overflow: 'hidden',
    flexWrap: ['wrap', 'wrap', 'nowrap'],
    padding: ['1.5rem', '2rem', '0rem'],
    '.title': {
      variant: 'customVariants.title',
      order: 1,
      display: 'none',
      textTransform: 'unset'
    },
    '.text': {
      variant: 'customVariants.text',
      order: 3,
      p: '1rem 2rem',
      color: 'white',
      opacity: '1',
      backgroundColor: '#312c2cde',
      fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem', '0.9rem'],
      textTransform: 'uppercase'
    },
    '.date': {
      order: 1,
      color: 'dark',
      backgroundColor: 'secondary',
      fontSize: ['0.8rem', '0.8rem', '0.8rem', '0.8rem', '0.8rem'],
      maxWidth: ['unset', '', '', '130px'],
      flexGrow: 1
    },
    '.imageContainer': {},
    '.shoutCTABtns ': {}
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    height: ['auto', 'auto', 'auto', 'auto'],
    minHeight: '60vh',
    pt: '10vh',
    pb: '20vh',
    // backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/o_60/v1688394168/sites/nomade/pothos.webp)',
    // backgroundRepeat: 'no-repeat',
    // backgroundAttachment: ['unset', 'unset', 'unset', 'unset'],
    // backgroundSize: ['30%', '30%'],
    // backgroundPosition: 'top left',
    '.section': {
      textAlign: 'center',
      // margin: ['auto', 'auto', 'auto auto auto 30%'],
      '.title': {
        color: '#787878'
      },
      '.text': {
        mb: '2rem'
      }
    }
  },

  homepageReservations: {
    variant: 'customVariants.sideBySide1',
    position: 'relative',
    mt: '7.5vw',
    '::before': {
      content: '""',
      position: 'absolute',
      top: '0%',
      left: '0%',
      transform: 'translateY(-100%)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: '0% 104%',
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1690377078/sites/nomade/wave.svg)',
      width: '100%',
      height: '23vw'
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    pt: '5vh',
    pb: '15vh',
    backgroundColor: 'backgroundSecondary',
    height: ['auto', 'auto', 'auto', 'auto'],
    backgroundAttachment: ['', '', 'unset'],
    '.section': {
      textAlign: 'center',
      '.title': {
        color: 'text2'
      }
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1'
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },

  homepageSlider: {
    '.slick-initialized .slick-slide > div, .slick-slider, .slick-list, .slick-track, .slick-slide > div, .slick-slide img':
      {
        height: '100vh',
        objectFit: 'cover'
      },
    '.slick-dots': {
      display: 'none !important'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection1: {
    marginTop: '135px',
    padding: '10vh 2rem 10vh',
    backgroundColor: 'white',
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4.5rem'],
      color: '#be9c5d',
      fontWeight: '300'
    },
    '.text': {
      lineHeight: '2.5'
    }
  },

  aboutSection2: {
    marginTop: '135px',
    padding: '0rem',
    backgroundColor: '#fbf5ed',
    pb: '0vw',
    '.lazyload-wrapper': {
      width: ['', '', '55%', '60%']
    },
    '.content': {
      padding: ['', '', '7.5vw'],
      width: ['', '', '45%', '40%']
    },
    '.title': {
      variant: 'customVariants.title',
      borderColor: 'primary',
      color: '#a89f8f',
      maxWidth: '400px',
      fontWeight: '300'
    },
    '.text': {
      color: '#a89f8f',
      lineHeight: '2'
    }
  },
  aboutSection3: {
    '::before': {
      content: '""',
      position: 'absolute',
      top: '0%',
      left: '0%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/a_180/v1690467128/sites/nomade/wave2.png)',
      width: '100%',
      height: '20vw'
    },
    padding: '15vw 5vw 15vw',

    '.section': {
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      alignItems: 'flex-start',
      paddingTop: ['10vw', '15vw', '', '', '10vw'],
      maxWidth: '1600px'
    },
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary',
      fontSize: ['2rem', '2rem', '2.25rem', '2.5rem', '3rem'],
      color: '#be9c5d',
      mb: ['2rem', '', '4rem'],
      textAlign: 'left',
      mr: ['', '', '10%']
    },
    '.text': {
      lineHeight: '2',
      pl: ['', '', '5%'],
      p: {
        m: '0rem'
      },
      textAlign: 'left'
    }
  },

  aboutPageSlider: {
    '.slick-initialized .slick-slide > div, .slick-slider, .slick-list, .slick-track, .slick-slide > div, .slick-slide img':
      {
        height: '60vh',
        objectFit: 'cover'
      }
  },

  // ? =======================
  // ? =====  Menu page  =====
  // ? =======================

  menuIntro: {
    mt: '130px',
    color: '#a89f8f',

    '.title': {
      fontSize: ['2rem', '2.25rem', '2.75rem', '3.5rem'],
      fontWeight: '300'
    },
    '.subtitle': {
      order: '2'
    }
  },

  menuCTAS: {},

  menu: {},

  // ? ========================
  // ? ====  Partners page  ====
  // ? ========================

  ourPartners: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    marginTop: '135px',
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    marginTop: '135px',
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary',
      display: 'none'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactPagehero: {
    variant: 'customVariants.homepageAbout',
    minHeight: ['90vh', '90vh', '90vh', '90vh', '90vh'],
    pt: '200px',
    pb: '35vh'
  },

  contactPage1: {
    '::before': {
      content: '""',
      top: '1%',
      left: '0%',
      transform: 'translateY(-100%)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      // transform: 'translateY(-100%)',
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1690467128/sites/nomade/wave.png)',
      width: '100%',
      height: '20vw',
      position: 'absolute'

      //   filter: 'brightness(0)'
    },
    background: 'backgroundSecondary',
    color: 'text',
    pb: '20vh'
  },

  contactForm: {
    order: '4',

    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      color: 'text'
    },
    h3: {
      mb: '2rem',
      color: 'text'
    },
    '.text': {
      color: 'text'
    },
    backgroundPosition: 'center center',
    color: 'white',
    background: 'none',
    backgroundColor: 'backgroundSecondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    borderTop: '2px solid',
    borderColor: 'text',
    form: {
      maxWidth: '500px'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'text',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'text',
      fontFamily: 'heading',
      '::placeholder': {
        color: 'text'
      }
    },
    label: {
      color: 'text'
    },
    textarea: {
      '::placeholder': {
        color: 'text',
        fontWeight: '300',
        fontFamily: 'heading'
      }
    },

    '.submitBtn': {
      variant: 'buttons.primary',
      padding: '0.5rem 1rem',
      height: 'unset',
      width: 'auto',
      marginTop: '1.5rem',
      borderColor: 'white',
      color: 'text'
      //   ':hover': {
      //     borderColor: 'dark',
      //     backgroundColor: 'dark'
      //   }
    }
  },

  // locationMap: {
  //   order: '3',
  //   '.content_container': {
  //     padding: '0rem',
  //     justifyContent: 'flex-start',
  //     width: ['', '', '', '35%']
  //   },
  //   iframe: {
  //     filter: 'hue-rotate(-181deg) sepia(0.1)',
  //     width: ['', '', '', '65%']
  //   },
  //   h3: {
  //     variant: 'customVariants.title',
  //     justifyContent: 'center',
  //     display: 'flex',
  //     order: 'unset',
  //     width: '100%',
  //     backgroundColor: '#c3b7a7',
  //     padding: '0.5rem 0.5rem 1rem',
  //     textAlign: 'center',
  //     fontSize: ['1rem', '1.25rem', '1.25rem', '1.25rem', '1.25rem'],
  //     alignItems: 'center',
  //     color: 'white',
  //     marginBottom: '0rem !important'
  //   },
  //   '.contactDetails-container': {
  //     padding: '1rem 1rem 2rem',
  //     '.text': {
  //       fontSize: '0.9rem'
  //     }
  //   },
  //   '.hours-section': {
  //     maxWidth: 'unset',
  //     padding: '0rem',
  //     '.hoursContainer': {
  //       padding: ['1rem', '2rem', '3rem 4rem', '1rem 5rem'],
  //       maxWidth: '500px',
  //       m: 'auto',
  //       '.dayContainer': {
  //         mb: '0.5rem'
  //       },
  //       '.dayofWeekText': {
  //         my: '0.5rem',
  //         border: 'none',
  //         fontSize: '1.5em'
  //       },
  //       '.timeBlocks': {
  //         justifyContent: 'flex-start'
  //       }
  //     }
  //   },
  //   '.dayofWeekText': {
  //     margin: '0 auto 1rem 0',
  //     borderBottom: '1px solid'
  //   },
  //   '.textContent': {
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //     textAlign: 'center',
  //     display: 'flex'
  //   }
  // },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      textAign: 'left',
      margin: '1rem',
      borderBottom: '2px solid',
      width: 'calc(100% - 2rem)',
      fontSize: ['2rem', '2.5rem', '2.5rem'],
      textAlign: 'left',
      justifyContent: 'flex-start'
    },

    '.boxesContainer': {
      justifyContent: 'flex-start'
    },
    '.box': {
      flexGrow: 1,
      maxWidth: '600px'
    },
    '.textContent': {
      backgroundColor: 'white',
      '.subtitle': {
        color: 'dark',
        fontSize: '1rem'
      }
    },
    '.imageContainer': {
      backgroundColor: 'white',
      img: {
        objectFit: 'cover',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ============================================
  // ? ====  Private Events and Catering page  ====
  // ? ============================================

  specialEventsHero: {
    height: '100vh',
    padding: '5vw',
    '.section': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      margin: '0rem',
      color: 'white',
      '.title': {
        color: 'white'
      }
    }
  },

  specialEvents1: {
    padding: '15vh 2rem 10vh',
    '.section': {
      maxWidth: '900px'
    },
    '.title': {
      variant: 'customVariants.title',
      mb: '3rem',
      color: '#a89f8fd6'
    },
    '.text': {
      color: '#a89f8fd6',
      fontSize: '0.8rem',
      letterSpacing: '2px'
    }
  },
  specialEvents2: {
    backgroundColor: '#fbf5ee',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0rem',
    marginTop: '20vw',
    '::before': {
      content: '""',
      top: '0%',
      left: '0%',
      transform: 'translateY(-100%)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      // transform: 'translateY(-100%)',
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/v1690467128/sites/nomade/wave2.png)',
      width: '100%',
      height: '20vw',
      position: 'absolute'
    },
    '.section': {
      padding: '5vh 5vw'
    },
    // padding: '10vh 2rem 10vh',
    letterSpacing: '2px'
  },

  specialEvents3: {
    position: 'relative',
    padding: '20vh 5vw 20vh',
    '::before': {
      content: '""',
      position: 'absolute',
      top: '0%',
      left: '0%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundImage: 'url(https://res.cloudinary.com/gonation/image/upload/a_180/v1690467128/sites/nomade/wave2.png)',
      width: '100%',
      height: '20vw'
    },
    padding: '30vh 0rem 0rem',
    display: 'flex',
    flexDirection: ['column', 'column', 'column', 'column'],
    justifyContent: 'center',
    alignItems: 'center',
    '.content': {
      width: ['100%', '100%', '100%', '100%'],
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '767px',
      margin: 'auto',
      py: '10vh',
      '.title': {
        variant: 'customVariants.title',
        borderBottom: '2px solid',
        borderColor: 'primary',
        mb: '3rem'
      },
      'div.text': {
        variant: 'customVariants.text',
        a: {
          color: 'tertiary',
          display: 'inline',
          textDecoration: 'underline'
        }
      }
    },
    '.lazyload-wrapper': {
      width: ['100%', '100%', '100%', '100%']
    }
  },

  // ? ====================
  // ? ==== Gift Card  ====
  // ? ====================

  giftCard1: {
    variant: 'customVariants.sideBySide1',
    mt: '135px',
    height: ['calc(100vh - 135px)', 'calc(100vh - 135px)', 'calc(100vh - 135px)', 'calc(100vh - 135px)'],
    color: 'white',
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary',
      mb: '3rem'
    },
    '.text': {
      opacity: '1'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      mt: '2.5rem',
      padding: '1rem 3rem',
      fontSize: ['1.25rem', '1.75rem', '1.75rem', '1.75rem', '1.75rem'],
      borderColor: 'white',
      color: 'white',
      borderRadius: '500px'
    }
  },

  // ? ======================
  // ? ==== The Terrace  ====
  // ? ======================

  theTerrace1: {
    padding: '10vh 5vw',
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  }
}
